import { Subjects } from '@/libs/acl/ability'
import i18n from '@/libs/i18n'

export default [
  {
    path: '/management/users',
    name: 'users',
    component: () => import('@/views/loggedin/management/users/index.vue'),
    meta: {
      pageTitle: i18n.t('Management.Users.Users'),
      resource: Subjects.Users,
    },
  },
  {
    path: '/management/users/:id',
    name: 'users-edit',
    component: () => import('@/views/loggedin/management/users/edit.vue'),
    meta: {
      pageTitle: i18n.t('Management.Users.Users'),
      resource: Subjects.Users,
    },
  },
  {
    path: '/management/employees',
    name: 'employees',
    component: () => import('@/views/loggedin/management/employees/index.vue'),
    meta: {
      pageTitle: i18n.t('Management.Employees.Employees'),
      resource: Subjects.Employees,
    },
  },
  {
    path: '/management/employees/:id',
    name: 'employee-edit',
    component: () => import('@/views/loggedin/management/employees/edit.vue'),
    meta: {
      pageTitle: i18n.t('Management.Employees.Employees'),
      resource: Subjects.Employees,
    },
  },
  {
    path: '/management/absences',
    name: 'absences',
    component: () => import('@/views/loggedin/management/absences/index.vue'),
    meta: {
      pageTitle: i18n.t('Absences.Absences'),
      resource: Subjects.Absences,
    },
  },
  {
    path: '/management/layers',
    name: 'layers',
    component: () => import('@/views/loggedin/management/layers/index.vue'),
    meta: {
      pageTitle: i18n.t('Management.Layers.Layers'),
      resource: Subjects.Layers,
    },
  },
  {
    path: '/management/layers/:id',
    name: 'layers-configure',
    component: () => import('@/views/loggedin/management/layers/configure.vue'),
    meta: {
      pageTitle: i18n.t('Management.Layers.Configure.Configure'),
      resource: Subjects.Layers,
    },
  },
  {
    path: '/management/orders',
    name: 'orders',
    component: () => import('@/views/loggedin/management/orders/index.vue'),
    meta: {
      pageTitle: i18n.tc('Management.Orders.Orders', 0),
      resource: Subjects.Orders,
    },
  },
  {
    path: '/management/service-provider',
    name: 'service-provider',
    component: () =>
      import('@/views/loggedin/management/service-provider/index.vue'),
    meta: {
      pageTitle: i18n.t('Management.ServiceProviders.ServiceProviders'),
      resource: Subjects.ServiceProvider,
    },
  },
]
