import { Subjects } from '@/libs/acl/ability'
import i18n from '@/libs/i18n'
import management from './management'

export default [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/loggedin/home/index.vue'),
    meta: {
      pageTitle: i18n.t('Global.Home'),
      resource: Subjects.Public,
    },
  },
  ...management,
  {
    path: '/notification-of-need',
    name: 'notification-of-need',
    component: () => import('@/views/loggedin/notification-of-need/index.vue'),
    meta: {
      pageTitle: i18n.t('NotificationOfNeed.NotificationOfNeed'),
      resource: Subjects.NotificationOfNeed,
    },
  },
  {
    path: '/manage-shifts',
    name: 'manage-shifts',
    component: () => import('@/views/loggedin/manage-shifts/index.vue'),
    meta: {
      pageTitle: i18n.t('ManageShifts.ManageShifts'),
      resource: Subjects.ManageShifts,
    },
  },
  {
    path: '/manage-shifts/print',
    name: 'manage-shifts-print',
    component: () => import('@/views/loggedin/manage-shifts/print.vue'),
    meta: {
      layout: 'full',
      pageTitle: i18n.t('ManageShifts.ManageShifts'),
      resource: Subjects.ManageShifts,
    },
  },
  {
    path: '/staff-scheduling',
    name: 'staff-scheduling',
    component: () => import('@/views/loggedin/staff-scheduling/index.vue'),
    meta: {
      pageTitle: i18n.t('StaffScheduling.StaffScheduling'),
      resource: Subjects.StaffScheduling,
    },
  },
  {
    path: '/staff-scheduling/service-provider/:token',
    name: 'staff-scheduling-service-provider',
    component: () =>
      import('@/views/loggedin/staff-scheduling/service-provider.vue'),
    meta: {
      layout: 'full',
      noAuth: true,
    },
  },
  {
    path: '/staff-scheduling/:layer/:timestamp',
    name: 'staff-scheduling-pdf',
    component: () => import('@/views/loggedin/staff-scheduling/pdf.vue'),
    meta: {
      layout: 'full',
      resource: Subjects.StaffScheduling,
    },
  },
  {
    path: '/evaluations/total-cost',
    name: 'total-cost',
    component: () =>
      import('@/views/loggedin/evaluations/total-cost/index.vue'),
    meta: {
      pageTitle: i18n.t('Evaluations.TotalCost.TotalCost'),
      resource: Subjects.TotalCost,
    },
  },
  {
    path: '/evaluations/internal-shifts',
    name: 'internal-shifts',
    component: () =>
      import('@/views/loggedin/evaluations/internal-shifts/index.vue'),
    meta: {
      pageTitle: i18n.t('Evaluations.InternalShifts.InternalShifts'),
      resource: Subjects.InternalShifts,
    },
  },
]
