const RolesEnum = {
  TechnicalAdmin: 'TechnicalAdmin',
  Admin: 'Admin',
  ShiftSupervisor: 'ShiftSupervisor',
  Invoice: 'Invoice',
  Employee: 'Employee',
  ServiceProvider: 'ServiceProvider',
}

export const Roles = [
  RolesEnum.Admin,
  RolesEnum.ShiftSupervisor,
  RolesEnum.Invoice,
]

export { RolesEnum }
