export default [
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      noAuth: true,
    },
  },
  {
    path: '/not-authorized',
    name: 'not-authorized',
    component: () => import('@/views/error/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      noAuth: true,
    },
  },
  {
    path: '/token-invalid',
    name: 'token-invalid',
    component: () => import('@/views/error/TokenInvalid.vue'),
    meta: {
      layout: 'full',
      noAuth: true,
    },
  },
]
