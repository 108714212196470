export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    meta: {
      layout: 'full',
      noAuth: true,
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/set-password/:token',
    name: 'set-password',
    component: () => import('@/views/auth/SetPassword.vue'),
    meta: {
      layout: 'full',
      noAuth: true,
    },
  },
]
