import { parseJwt } from '@/auth/utils'
import { RolesEnum } from '@/data/enums'
import { Ability, AbilityBuilder } from '@casl/ability'
import Vue from 'vue'

export const Action = {
  Manage: 'manage', // manage is a special keyword in CASL which represents "any" action.
  Create: 'create',
  Read: 'read',
  Update: 'update',
  Delete: 'delete',

  // custom
  Approve: 'approve',
  Transmit: 'transmit',
}

export const Subjects = {
  All: 'all',
  Public: 'public',
  Users: 'users',
  Employees: 'employees',
  Layers: 'layers',
  Orders: 'orders',
  ServiceProvider: 'serviceProvider',
  TotalCost: 'totalCost',
  InternalShifts: 'internalShifts',
  NotificationOfNeed: 'notificationOfNeed',
  StaffScheduling: 'staffScheduling',
  ManageShifts: 'manageShifts',
  Absences: 'absences',
}

Vue.prototype.$acl = {
  action: Action,
  subjects: Subjects,
}

export const updateAbility = role => {
  const { can, cannot, rules } = new AbilityBuilder(Ability)

  if (role === RolesEnum.Admin || role === RolesEnum.TechnicalAdmin) {
    can(Action.Manage, Subjects.All)
  } else if (role === RolesEnum.ShiftSupervisor) {
    can(Action.Manage, Subjects.NotificationOfNeed)
    can(Action.Read, Subjects.StaffScheduling)
    can(Action.Manage, Subjects.ManageShifts)
    cannot(Action.Approve, Subjects.NotificationOfNeed)
  } else if (role === RolesEnum.Invoice) {
    can(Action.Read, Subjects.StaffScheduling)
    can(Action.Manage, Subjects.TotalCost)
    can(Action.Manage, Subjects.InternalShifts)
    can(Action.Manage, Subjects.Employees)
  } else if (role === RolesEnum.ServiceProvider) {
    can(Action.Manage, Subjects.StaffScheduling)
  }

  return rules
}

const initialRole = () => {
  const token = localStorage.getItem('accessToken')
  if (!token) return []
  const { role } = parseJwt(token)
  return updateAbility(role)
}

export const isAdmin = () => {
  const token = localStorage.getItem('accessToken')
  if (!token) return false
  const { role } = parseJwt(token)
  return role === RolesEnum.Admin || role === RolesEnum.TechnicalAdmin
}

export default new Ability(initialRole())
